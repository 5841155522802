import { useStateContext } from "../../context/ContextProvider";
import { Helmet } from "react-helmet";
import ServiceCard from "@/components/Services/ServiceCard";

const Services = () => {
	const context = useStateContext();

	if (!context) {
		return;
	}

	const { serviceTypesData } = context;

	const colors = ["bg-blue-300", "bg-red-300", "bg-green-300", "bg-yellow-300"];

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<meta
					name="description"
					content="We offer free collection and delivery of your laundry for Wash, Dry & Fold | Wash, Dry & Iron | Ironing."
				/>
				<title>Laundry Services | Laundro</title>
				<link rel="canonical" href="https://laundro.co.uk/services" />
			</Helmet>

			<div className="flex flex-wrap justify-center items-center gap-1 px-1">
				{serviceTypesData
					?.filter((serviceType) => serviceType.shortCode !== "ironing")
					.map((serviceType, index) => (
						<div
							className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2"
							key={serviceType.shortCode}
						>
							<ServiceCard
								serviceType={serviceType}
								color={colors[index % colors.length]}
							/>
						</div>
					))}
			</div>
		</>
	);
};

export default Services;
